import React, { Fragment, Component, ReactNode } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  PopupProps,
  MarkerProps,
  MapContainerProps,
} from 'react-leaflet';
import { Col, Row } from 'antd';
import * as Leaflet from 'leaflet';

import {
  FloorPlanMapDetailsType,
  MapBoundsType,
  MapCoordinatesType,
  MapMountParamsType,
} from '../@types';

import 'leaflet/dist/leaflet.css';
import './styles/index.scss';
import { getLeafletIcon, getMapBoundsAndCenter } from '..';

interface PropsType {
  mapDetails: FloorPlanMapDetailsType;
  mapZoom?: number;
  markerIconColor?: 'amber' | 'red' | 'green' | 'grey' | 'blue';
  popupProps?: Partial<PopupProps>;
  markerProps?: Partial<MarkerProps>;
  mapContainerProps?: Partial<MapContainerProps>;
  coordinates?: MapCoordinatesType;
  setMaxBounds?: boolean;
  onMapMount?: (params: MapMountParamsType) => void;
  popupContentRender?: (coordinates: MapCoordinatesType) => ReactNode;
  markerRender?: () => ReactNode;
  popupRender?: () => ReactNode;
}

interface StateType {
  mapBounds?: MapBoundsType;
  mapInstance?: Leaflet.Map;
  mapCenter: MapCoordinatesType;
}

const customCRSSimple = (Leaflet as any).extend({}, Leaflet.CRS.Simple, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(1, 0, 1, 0),
});

export class FloorPlanMap extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { mapDetails } = props;

    let bounds: MapBoundsType | undefined;
    let center: MapCoordinatesType | undefined;

    if (mapDetails.height && mapDetails.width) {
      const result = getMapBoundsAndCenter({
        height: Number(mapDetails.height),
        width: Number(mapDetails.width),
      });

      bounds = result.bounds;
      center = result.centre;
    }

    this.state = {
      mapBounds: bounds,
      mapInstance: undefined,
      mapCenter: center ?? [0, 0],
    };
  }

  handleMap = (map: Leaflet.Map) => {
    const { onMapMount } = this.props;
    const { mapCenter, mapBounds } = this.state;
    if (map) {
      map.fitBounds(mapBounds ?? map.getBounds());
      onMapMount?.({
        map,
        center: mapCenter,
        zoom: map.getZoom(),
        minZoom: map.getMinZoom(),
        mapBounds,
      });
    }
  };

  render() {
    const {
      mapDetails,
      popupRender,
      popupContentRender,
      markerIconColor,
      markerProps,
      popupProps,
      coordinates,
      mapZoom,
      markerRender,
      setMaxBounds,
    } = this.props;
    let { mapContainerProps = {} } = this.props;
    const { mapBounds, mapCenter } = this.state;
    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image ?? ''
    )}`;

    const mapCoordinates = coordinates ?? mapCenter ?? [0, 0];

    mapContainerProps.className = `floor-plan-map ${mapContainerProps?.className ??
      ''}`;

    if (setMaxBounds) {
      mapContainerProps.maxBounds = mapBounds;
    }

    return (
      <Fragment>
        <MapContainer
          tap={false}
          minZoom={mapZoom ?? -1}
          zoom={mapZoom ?? -1}
          center={mapCenter ?? [0, 0]}
          bounds={mapBounds}
          // crs={Leaflet.CRS.Simple}
          crs={customCRSSimple}
          whenCreated={this.handleMap}
          {...mapContainerProps}
        >
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}

          {markerRender ? (
            markerRender()
          ) : (
            <Marker
              {...markerProps}
              position={mapCoordinates}
              icon={getLeafletIcon(markerIconColor ?? 'blue')}
            >
              {popupRender ? (
                popupRender()
              ) : (
                <Popup {...popupProps}>
                  {popupContentRender ? (
                    popupContentRender(mapCoordinates)
                  ) : (
                    <Row>
                      <Col xs={24}>Latitude: {mapCoordinates[0]}</Col>
                      <Col xs={24}> Longitude: {mapCoordinates[1]} </Col>
                    </Row>
                  )}
                </Popup>
              )}
            </Marker>
          )}
        </MapContainer>
      </Fragment>
    );
  }
}
